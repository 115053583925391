// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader--text {
  position: absolute;
  top: 200%;
  left: 0;
  right: 0;
  margin: auto;
}

.loader--text:after {
  content: "Loading";
  font-weight: bold;
  animation-name: loading-text;
  animation-duration: 3s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  15% {
    transform: translateX(0);
  }
  45% {
    transform: translateX(230px);
  }
  65% {
    transform: translateX(230px);
  }
  95% {
    transform: translateX(0);
  }
}
@keyframes loading-text {
  0% {
    content: "Loading";
  }
  25% {
    content: "Loading.";
  }
  50% {
    content: "Loading..";
  }
  75% {
    content: "Loading...";
  }
}`, "",{"version":3,"sources":["webpack://./src/app/auth/login/login.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;AACF;;AACA;EACE,kBAAA;EACA,iBAAA;EACA,4BAAA;EACA,sBAAA;EACA,mCAAA;AAEF;;AACA;EACE;IACE,wBAAA;EAEF;EAAA;IACE,4BAAA;EAEF;EAAA;IACE,4BAAA;EAEF;EAAA;IACE,wBAAA;EAEF;AACF;AAAA;EACE;IACE,kBAAA;EAEF;EAAA;IACE,mBAAA;EAEF;EAAA;IACE,oBAAA;EAEF;EAAA;IACE,qBAAA;EAEF;AACF","sourcesContent":[".loader--text {\r\n  position: absolute;\r\n  top: 200%;\r\n  left: 0;\r\n  right: 0;\r\n  margin: auto;\r\n}\r\n.loader--text:after {\r\n  content: \"Loading\";\r\n  font-weight: bold;\r\n  animation-name: loading-text;\r\n  animation-duration: 3s;\r\n  animation-iteration-count: infinite;\r\n}\r\n\r\n@keyframes loader {\r\n  15% {\r\n    transform: translateX(0);\r\n  }\r\n  45% {\r\n    transform: translateX(230px);\r\n  }\r\n  65% {\r\n    transform: translateX(230px);\r\n  }\r\n  95% {\r\n    transform: translateX(0);\r\n  }\r\n}\r\n@keyframes loading-text {\r\n  0% {\r\n    content: \"Loading\";\r\n  }\r\n  25% {\r\n    content: \"Loading.\";\r\n  }\r\n  50% {\r\n    content: \"Loading..\";\r\n  }\r\n  75% {\r\n    content: \"Loading...\";\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
